import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import { sortBy, uniqBy } from 'lodash';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Brow from '../components/Brow';
import CTA from '../components/CTA';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout/index-noheader';

const CLIENT_ID = '369895063682-4r9hmmhg330bb66mjvutm3k716ki3lu4.apps.googleusercontent.com';
const styles = css`
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-8px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  .form {
    pointer-events: all;
    margin: 0 auto;
    max-width: 43rem;
    padding: 0 0;
    position: relative;
    color: #fff;
  }

  .button > * {
    margin: auto;
  }

  .signout {
    position: relative;
    background: none;
    border: 0;
    display: block;
    max-width: 39.5rem;
    width: 100%;
    text-align: right;
    margin: 0 auto 0;
    padding: 0;
    text-decoration: underline;
    color: #75a3ce;
    cursor: pointer;
    font-size: 1rem;
    z-index: 4;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .selectwrap {
    & label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: bold;
      text-align: center;
      & em {
        font-weight: normal;
        white-space: nowrap;
      }
    }

    & select {
      appearance: none;
      background: #000;
      color: #fff;
      position: relative;
      display: block;
      font-size: 1.125rem;
      line-height: 1.5;
      padding: 0.75rem;
      margin: 0 auto;
      width: 100%;
      max-width: 40rem;
      height: auto;
      border-radius: 5px;
      border: 0;
      box-shadow: rgba(50, 50, 93, 0.3) 0px 50px 100px -20px, rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      cursor: pointer;
      outline-color: white;
      outline-offset: 4px;
      transition: 0.1s ease-in-out background;

      &:hover {
        background: rgb(23, 23, 50);
      }

      &:not(:focus-visible) {
        outline: none;
      }
    }
  }

  .bothwrapper {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    min-height: calc(3rem + 100vh);
  }

  .headerwrapper {
    position: sticky;
    top: 0;
    padding-top: 1rem;
    width: 100%;
    z-index: 2;
    display: grid;
    place-items: center;
    pointer-events: none;

    grid-row: 1/2;
    grid-column: 1/2;

    & > header {
      padding-top: 1rem !important;
      padding-bottom: 1rem;
      overflow: visible !important;

      & [data-gatsby-image-wrapper] {
        padding: 0 !important;
      }
    }

    @media (min-height: 750px) {
      padding-top: 25vh;
    }
  }

  .sizesWrapper {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    min-height: 100vh;
    display: grid;
    place-items: center;

    grid-row: 1/2;
    grid-column: 1/2;

    &::before {
      content: ' ';
      position: absolute;
      z-index: 3;
      pointer-events: none;
      height: 100%;
      top: 0;
      left: 0;
      width: 40px;
      background: linear-gradient(to right, rgb(53, 53, 70), rgb(53 53 70 / 0));
    }

    &::after {
      content: ' ';
      position: absolute;
      z-index: 3;
      pointer-events: none;
      height: 100%;
      top: 0;
      right: 0;
      width: 40px;
      background: linear-gradient(to left, rgb(53, 53, 70), rgb(53 53 70 / 0));
    }
  }

  .sizeOverview {
    position: relative;
    width: 110vw;
    left: -5vw;
    min-height: 100vh;
    margin-bottom: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    transform-origin: top center;

    & .dimensions {
      position: relative;
      border-radius: 15px;
      background: linear-gradient(130deg, #0079b1, ease-in-out, #00b179);
      opacity: 0.2;
      transform: perspective(500px);
      transition: 0.25s ease-in-out all;
      display: grid;
      place-items: center;
      color: #fff4;
      font-size: 14px;
      user-select: none;

      &:hover {
        color: #fff;
        z-index: 1;
        opacity: 1;
        box-shadow: 0px 20px 100px 20px rgb(53, 53, 70);

        &::before {
          left: 10px;
          top: 10px;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
        }
      }

      &::before {
        content: ' ';
        background: rgb(53, 53, 70);
        border-radius: 10px;
        position: absolute;
        left: 5px;
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        transition: 0.25s ease-in-out all;
      }
      & span {
        overflow: hidden;
        display: block;
        z-index: 1;
      }
    }
  }

  .sizesList {
    position: relative;
    color: #fff;
    pointer-events: all;
    font-size: 18px;
    max-width: 40rem;
    padding: 0 1rem;
    margin: 0 auto;
  }

  .arrowDown {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    animation: float 4s ease-in-out infinite;
  }

  .primaryButton {
    position: relative;
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    background: linear-gradient(135deg, #2c82ad, #67b26f);
    border-radius: 40px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    padding: 0;
    margin-top: 1.5rem;

    & span {
      position: relative;
      z-index: 1;
      padding: 0.33em 1rem;
      content: ' ';
      display: block;
      background: #1c729d;
      width: calc(100%);
      height: calc(100%);
      z-index: 0;
      opacity: 1;
      transition: background 0.25s ease-in-out;
      border-radius: 40px;
    }

    & svg {
      vertical-align: middle;
      margin-left: 10px;
    }

    @media (min-width: 600px) {
      & span {
        font-size: 1.125rem;
        padding: 0.5em 1rem;
      }
    }

    &:hocus {
      text-decoration: none;
      & span {
        background: transparent;
      }
    }
  }

  .button {
    position: relative;
    display: grid;
    place-items: center;
  }
  .button > * {
    margin: auto;
  }
  .signin,
  .signout {
    border-radius: 4px;
    box-sizing: border-box;
    transition: background-color 0.218s, border-color 0.218s;
    -moz-user-select: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dadce0;
    color: #3c4043;
    cursor: pointer;
    font-family: 'Google Sans', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 4px 12px 4px 3px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    background-color: #1a73e8;
    border: none;
    font-weight: bold;
    color: #fff;
    display: inline-flex;
    margin: 0 auto;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;

    &:hover {
      background: #5194ee;
    }

    & span {
      display: inline-grid;
      place-items: center;
      width: 34px;
      height: 34px;
      background: #fff;
      border-radius: 3px 0 0 3px;

      & svg {
        width: 18px;
        height: 18px;
      }
    }
  }
`;

const cutOffPercentage = 0.0005;

const SORTBY = {
  OCCURENCE: 'OCCURENCE',
  SIZE: 'SIZE',
};

const sortFunction = {
  [SORTBY.OCCURENCE]: (item) => -item.occurence,
  [SORTBY.SIZE]: (item) => parseInt(item.dimensions.split('x')[1], 10),
};

const defaultList = [
  { dimensions: '1900x940', occurence: 625 },
  { dimensions: '1900x970', occurence: 361 },
  { dimensions: '1520x720', occurence: 342 },
  { dimensions: '1350x630', occurence: 339 },
  { dimensions: '1350x660', occurence: 327 },
  { dimensions: '380x640', occurence: 263 },
  { dimensions: '1520x750', occurence: 259 },
  { dimensions: '410x720', occurence: 211 },
  { dimensions: '1920x940', occurence: 117 },
  { dimensions: '390x660', occurence: 110 },
  { dimensions: '380x550', occurence: 102 },
  { dimensions: '1260x610', occurence: 93 },
  { dimensions: '1440x820', occurence: 88 },
  { dimensions: '1260x580', occurence: 87 },
  { dimensions: '1520x760', occurence: 83 },
  { dimensions: '1350x640', occurence: 79 },
  { dimensions: '1440x790', occurence: 71 },
  { dimensions: '1900x910', occurence: 70 },
  { dimensions: '1900x880', occurence: 68 },
  { dimensions: '1440x800', occurence: 66 },
  { dimensions: '390x730', occurence: 65 },
  { dimensions: '1280x800', occurence: 65 },
  { dimensions: '1440x760', occurence: 60 },
  { dimensions: '1350x670', occurence: 60 },
  { dimensions: '320x570', occurence: 59 },
  { dimensions: '410x780', occurence: 58 },
  { dimensions: '1440x730', occurence: 58 },
  { dimensions: '500x770', occurence: 57 },
  { dimensions: '1540x720', occurence: 57 },
  { dimensions: '410x760', occurence: 55 },
  { dimensions: '360x560', occurence: 54 },
  { dimensions: '410x730', occurence: 53 },
  { dimensions: '390x670', occurence: 51 },
];

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      chosenId: -1,
      properties: [],
      browserData: [],
      mostOccurence: 10000,
    };
  }

  componentDidMount() {
    const ref = this;

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);

    const script2 = document.createElement('script');
    script2.src = 'https://apis.google.com/js/api.js';
    document.body.appendChild(script2);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  getProperties = async () => {
    const ref = this;

    await new Promise((resolve, reject) => {
      window.gapi.load('client', { callback: resolve, onerror: reject });
    });

    await window.gapi.client.init({}).then(async () => {
      window.gapi.client.load('https://analytics.googleapis.com/$discovery/rest?version=v3');
    });

    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/analytics.readonly',
      callback: async (response) => {
        window.gapi.client.setToken({ access_token: response.access_token });

        window.gapi.client
          .request({
            path: 'https://www.googleapis.com/analytics/v3/management/accountSummaries',
            headers: { 'Content-Type': 'application/json' },
          })
          .then((result) => JSON.parse(result.body))
          .then((results) => results.items)
          .then((items) => {
            const properties = items
              .filter((item) => item.webProperties?.length)
              .map((item) => {
                const { name } = item;
                return item.webProperties.map((prop) => {
                  const propName = prop.name;
                  return prop.profiles.map((profile) => ({
                    name,
                    propName,
                    profileName: profile.name,
                    id: profile.id,
                  }));
                });
              })
              .flat(Infinity);

            ref.setState({ properties });
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });

    client.requestAccessToken();
  };

  logout = (e) => {
    e.preventDefault();
    let cred = window.gapi.client.getToken();
    if (cred !== null) {
      window.google.accounts.oauth2.revoke(cred.access_token, () => {
        console.log('Revoked: ' + cred.access_token);
      });
      window.gapi.client.setToken('');
    }

    this.setState({ properties: [], browserData: [], total: 10000 });
  };

  getBrowserSizes = (id) => {
    if (id === -1) {
      this.setState({ browserData: [] });
    }

    if (id > 0) {
      window.gapi.client
        .request({
          path: '/v4/reports:batchGet',
          root: 'https://analyticsreporting.googleapis.com/',
          method: 'POST',
          body: {
            reportRequests: [
              {
                viewId: `ga:${id}`,
                dateRanges: [
                  {
                    startDate: '2023-05-31',
                    endDate: '2023-06-30',
                  },
                ],
                metrics: [
                  {
                    expression: 'ga:sessions',
                  },
                ],
                dimensions: [
                  {
                    name: 'ga:browserSize',
                  },
                ],
                orderBys: [
                  {
                    fieldName: 'ga:sessions',
                    sortOrder: 'DESCENDING',
                  },
                ],
              },
            ],
          },
        })
        .then((response) => {
          const browserData = uniqBy(
            sortBy(
              response.result.reports[0].data.rows.map((item) => ({
                dimensions: item.dimensions[0],
                occurence: parseInt(item.metrics[0].values[0], 10),
              })),
              'occurence'
            )
              .filter((item) => item.dimensions !== '(not set)')
              .reverse(),
            'dimensions'
          );

          console.log('muh', browserData);

          this.setState({
            total: browserData.length,
            mostOccurence: browserData[0].occurence,
            browserData,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    document.documentElement.scrollTo({ top: 0 });
    this.getBrowserSizes(e.target.value);
  };

  render() {
    return (
      <Layout background="rgb(53, 53, 70)">
        <SEO
          title="Responsive website test"
          keywords={['']}
          description="Are you testing all the sizes you need to? See the viewports your visitors have used in June 2023, based on Google Analytics v3."
          ogFileName="responsivewebsitetest"
          ogCustomPath="/og-custom/"
          pathname={this.props.location.pathname}
        />
        <div className={styles.bothwrapper}>
          <div className={styles.headerwrapper}>
            <PageHeader nobg>
              <StaticImage
                src="../../assets/images/icon.png"
                width={64}
                height={64}
                alt=""
                style={{ borderRadius: 64 }}
              />
              <br />
              <Brow color="#75a3ce">Browser sizes for responsive design: device sizes are not enough</Brow>
              <h1 style={{ color: '#fff' }}>
                This is your <del>device</del> <ins>browser size</ins> wall.
              </h1>
              {this.state.properties.length ? (
                <div className={styles.form}>
                  {this.state.browserData.length > 0 ? (
                    <span style={{ color: '#fff' }}>
                      Your visitors have used{' '}
                      <strong data-class="polytip" data-tip="We're showing the top 100" data-background-color="#0079b1">
                        {this.state.total >= 999 ? 'over a thousand' : this.state.total} unique sizes
                        {this.state.total >= 999 ? '*' : ``}
                      </strong>{' '}
                      to see our site in the past 30 days.
                    </span>
                  ) : (
                    <span style={{ color: '#fff' }}>
                      If you only test device sizes you miss all the inbetween sizes your real visitors use.
                    </span>
                  )}

                  <div className={styles.selectwrap}>
                    <label htmlFor="viewid" style={{ fontSize: 0 }}>
                      Select a Google Analytics profile <em>(account &gt; property &gt; view)</em>
                    </label>
                    <select onChange={this.handleSubmit}>
                      <option value={-1}>Choose one of your sites (account › property › view)</option>
                      {this.state.properties.map((p) => (
                        <option key={p.id} value={p.id}>
                          {p.name} › {p.propName} › {p.profileName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}

              {this.state.properties.length ? null : (
                <div className={styles.form}>
                  <>
                    <span style={{ color: '#fff', marginBottom: '0.5rem', display: 'block' }}>
                      If you only test device sizes you miss all the inbetween sizes your real visitors use. Sign in
                      with Google and see the viewports your visitors have used in{' '}
                      <span style={{ textDecoration: 'line-through' }}>the past 30 days</span> June 2023.
                    </span>
                    <div className={styles.button}>
                      <button className={styles.signin} type="button" onClick={this.getProperties}>
                        <span>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            className="LgbsSe-Bz112c"
                          >
                            <g>
                              <path
                                fill="#EA4335"
                                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                              />
                              <path
                                fill="#4285F4"
                                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                              />
                              <path
                                fill="#FBBC05"
                                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                              />
                              <path
                                fill="#34A853"
                                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                              />
                              <path fill="none" d="M0 0h48v48H0z" />
                            </g>
                          </svg>
                        </span>
                        Sign In With Google
                      </button>
                    </div>
                    <span style={{ color: '#fff', fontStyle: 'italic', marginTop: '0.5rem', display: 'block' }}>
                      This web app runs entirely in your browser and no data is shared with Polypane.
                    </span>
                  </>
                </div>
              )}
              {this.state.properties.length && this.state.browserData.length ? (
                <div className={styles.arrowDown}>⌄</div>
              ) : null}
            </PageHeader>
          </div>
          <div className={styles.sizesWrapper}>
            <div className={styles.sizeOverview}>
              {sortBy(
                [
                  ...(this.state.properties.length && this.state.browserData.length
                    ? this.state.browserData.slice(0, 100)
                    : defaultList),
                ],
                sortFunction[SORTBY.SIZE]
              )
                .filter((item) => item.occurence / this.state.mostOccurence > cutOffPercentage)
                .map((item) => {
                  const width = parseInt(item.dimensions.split('x')[0], 10);
                  const height = parseInt(item.dimensions.split('x')[1], 10);
                  return (
                    <div
                      key={item.dimensions}
                      className={[styles.dimensions].join(' ')}
                      style={{
                        width: width / 4,
                        height: height / 4,
                      }}
                    >
                      <span>
                        {width}×{height}px
                        <br /> {item.occurence} visit{item.occurence === 1 ? '' : 's'}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {this.state.properties.length ? (
          <div className={styles.button}>
            <button className={styles.signout} type="button" onClick={this.logout}>
              <span>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c">
                  <g>
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    />
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    />
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    />
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    />
                    <path fill="none" d="M0 0h48v48H0z" />
                  </g>
                </svg>
              </span>
              Sign out with Google
            </button>
          </div>
        ) : null}

        <div className="widePage" style={{ color: '#fff', textAlign: 'center', marginTop: '5rem' }}>
          <div className="childPadding">
            <svg
              className={styles.icon}
              width="137"
              height="60"
              viewBox="0 0 137 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient id="sqgradient" gradientTransform="rotate(45)">
                  <stop offset="0" stopColor="rgb(44, 130, 173)" stopOpacity="1" />

                  <stop offset="1" stopColor="rgb(103, 178, 111)" stopOpacity="1" />
                </linearGradient>
              </defs>
              <rect width="53" height="60" rx="8" fill="url(#sqgradient)" />
              <rect x="60" width="43" height="50" rx="8" fill="url(#sqgradient)" />
              <rect x="110" width="27" height="37" rx="8" fill="url(#sqgradient)" />
            </svg>

            <h2 style={{ color: '#fff', marginTop: '2rem' }}>Create your own browser size wall</h2>
            <p>Create a Polypane workspace based on Google Analytics v3 data from June 2023:</p>
            <Link className={styles.primaryButton} to="/create-workspace/">
              <span>Create Browser size wall</span>
            </Link>
          </div>
        </div>

        <PageHeader nobg>
          <h2 className="h1" style={{ color: '#fff' }}>
            <StaticImage
              src="../../assets/images/icon.png"
              width={128}
              height={128}
              alt=""
              style={{ borderRadius: 128 }}
            />
            <br />
            Use Polypane to design, develop and test for all those viewports.
          </h2>
        </PageHeader>

        <article className="widePage" style={{ color: '#fff' }}>
          <div className="childPadding">
            <p>
              <strong>Testing only device sizes is not enough</strong>. Your users configure their browsers at many
              different sizes, on desktop but also on mobile and tablet.
            </p>

            <p>
              Unlike Chrome, Firefox and other "normal" browsers, Polypane understands responsive design. It shows your
              site in different screen sizes at the same time, whether that's device sizes, freeform sizes or your CSS
              media queries. Get the complete overview <strong>without endlessly resizing your browser</strong>.
            </p>

            <h3 style={{ color: '#fff' }}>More than just responsive design</h3>
            <p>
              Polypane includes social media previews, accessibility testing, broken link checking, contrast checkers,
              color blindness simulators and many other features to help you{' '}
              <strong>build the best websites and web apps</strong>.
            </p>
            <div style={{ textAlign: 'center', marginTop: '4rem', marginBottom: '6rem' }}>
              <h3 style={{ color: '#fff' }}>Polypane has a free 14 day trial. Try it today!</h3>
              <p>Available for Mac, Windows and Linux.</p>
            </div>
          </div>
        </article>

        <CTA darkbg />
      </Layout>
    );
  }
}

export default Index;
